import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import classNames from 'classnames';
import _ from 'lodash';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiChevronsDown, FiChevronsUp } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';

import styles from './styles.module.scss';
import { TimelineEntry } from './types';
import Button from '../../atoms/button/Button';
import { FormattedRelativeTimeConvenient } from '../../atoms/formatted-date-time/FormattedDateTime';
import { AugurCategory } from '../../molecules/augur-menu/types';

export type Props = {
  entries: TimelineEntry[];
  selectedPageCategory: AugurCategory;
  compareMode?: boolean;
  activeModelCode?: string;
  lastElementRef?: (node: HTMLDivElement | null) => void;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<unknown, unknown>> | void;
};

const MAX_HIGHLIGHTED_ENTRIES = 8;

const AugurTimeline: FC<Props> = ({
  entries,
  activeModelCode,
  lastElementRef,
  compareMode = false,
  selectedPageCategory,
  fetchNextPage,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const highlightedRefsMap = useRef<Map<string, HTMLDivElement>>(new Map());

  const entriesWithGridRow = useMemo(
    () =>
      entries.map((entry, index) => ({
        ...entry,
        gridRow: index + 1,
      })),
    [entries]
  );
  const [tooltipEntryId, setTooltipEntryId] = useState<string | null>(null);
  const [highlightedEntries, setHighlighted] = useState<
    Array<
      TimelineEntry & {
        gridRow: number;
        position: 'above' | 'below' | undefined;
      }
    >
  >([]);
  const [scrollTargetId, setScrollTargetId] = useState<string | null>(null);
  const [isScrolling, setIsScrolling] = useState(false);

  const checkVisibility = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const containerRect = container.getBoundingClientRect();
    const containerTop = container.scrollTop;
    const containerBottom = containerTop + containerRect.height;

    const newHighlightedEntries: Array<
      TimelineEntry & { gridRow: number; position: 'above' | 'below' }
    > = [];

    entriesWithGridRow.forEach((entry) => {
      if (!entry.highlighted) return;

      const element = container.querySelector(`[data-id="${entry.id}"]`);
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const elementTop = rect.top + container.scrollTop - containerRect.top;
      const elementBottom =
        rect.bottom + container.scrollTop - containerRect.top;

      if (elementBottom <= containerTop) {
        newHighlightedEntries.push({ ...entry, position: 'above' });
      } else if (elementTop >= containerBottom) {
        newHighlightedEntries.push({ ...entry, position: 'below' });
      }
    });

    setHighlighted((prevHighlighted) => {
      const updatedEntries = prevHighlighted.map((prevEntry) => {
        const newEntry = newHighlightedEntries.find(
          (entry) => entry.id === prevEntry.id
        );
        return newEntry
          ? { ...prevEntry, position: newEntry.position }
          : { ...prevEntry, position: undefined };
      });

      newHighlightedEntries.forEach((newEntry) => {
        if (!updatedEntries.some((entry) => entry.id === newEntry.id)) {
          updatedEntries.push(newEntry);
        }
      });

      updatedEntries.sort(
        (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
      );
      if (JSON.stringify(updatedEntries) !== JSON.stringify(prevHighlighted)) {
        return updatedEntries;
      }
      return prevHighlighted;
    });
  }, [entriesWithGridRow]);
  const smoothScroll = (element: Element, target: number, duration: number) => {
    const start = element.scrollTop;
    const change = target - start;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easeInOutCubic =
        progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      element.scrollTop = start + change * easeInOutCubic;

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };
  const scrollToElement = useCallback(
    async (id: string) => {
      const container = containerRef.current;
      if (!container) return;

      const targetElement = container.querySelector(`[data-id="${id}"]`);
      if (targetElement) {
        const elementRect = targetElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        const targetScrollTop =
          container.scrollTop +
          (elementRect.top - containerRect.top) -
          (containerRect.height - elementRect.height - 5);

        // Use custom smooth scroll with longer duration (e.g., 1500ms)
        smoothScroll(container, targetScrollTop, 2000);
        // Wait for smooth scroll to finish
        await new Promise((resolve) => setTimeout(resolve, 2100));

        const newElementRect = targetElement.getBoundingClientRect();
        const newContainerRect = container.getBoundingClientRect();

        const isNearBottom =
          Math.abs(newContainerRect.bottom - newElementRect.bottom - 5) < 2;

        if (isNearBottom) {
          setScrollTargetId(null);
          setIsScrolling(false);
        } else {
          setIsScrolling(true);
        }
      } else {
        const result = await fetchNextPage();
        if (result && 'data' in result && result.data.pages.length > 0) {
          setTimeout(() => scrollToElement(id), 100);
        } else {
          console.log('Element not found and no more pages to fetch');
          setScrollTargetId(null);
          setIsScrolling(false);
        }
      }

      checkVisibility();
    },
    [checkVisibility, fetchNextPage]
  );

  useEffect(() => {
    const debouncedCheckVisibility = _.debounce(checkVisibility, 1);

    const resizeObserver = new ResizeObserver(debouncedCheckVisibility);
    if (isScrolling && scrollTargetId) {
      void scrollToElement(scrollTargetId);
    }

    const scrollHandler = () => {
      debouncedCheckVisibility();
    };

    containerRef.current?.addEventListener('scroll', scrollHandler, {
      passive: true,
    });

    return () => {
      resizeObserver.disconnect();
      containerRef.current?.removeEventListener('scroll', scrollHandler);
      debouncedCheckVisibility.cancel();
    };
  }, [checkVisibility, isScrolling, scrollTargetId, scrollToElement]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        let shouldCheck = false;
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            shouldCheck = true;
          }
        });
        if (shouldCheck) {
          checkVisibility();
        }
      },
      { root: containerRef.current, threshold: 0.5 }
    );

    const highlightedElements = containerRef.current?.querySelectorAll(
      '[data-highlighted="true"]'
    );
    highlightedElements?.forEach((el) => intersectionObserver.observe(el));

    return () => intersectionObserver.disconnect();
  }, [checkVisibility]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const lastEntry = entries[0];
        if (lastEntry.isIntersecting && lastElementRef) {
          lastElementRef(lastEntry.target as HTMLDivElement);
        }
      },
      { root: containerRef.current, threshold: 0.5 }
    );

    const lastElement = containerRef.current?.lastElementChild;
    if (lastElement) {
      observer.observe(lastElement);
    }

    return () => observer.disconnect();
  }, [entriesWithGridRow, lastElementRef]);

  useEffect(() => {
    checkVisibility();
  }, [entriesWithGridRow, checkVisibility]);

  const scrollIntoView = (id: string) => {
    setScrollTargetId(id);
    setIsScrolling(true);
  };

  const renderEntry = (
    entry: TimelineEntry & { gridRow: number },
    isOutOfView = false,
    isAbove = false
  ) => {
    const Icon = entry.Icon;
    const entryStyle = isOutOfView
      ? {}
      : {
          gridRow: entry.gridRow,
        };
    return (
      <div
        data-tooltip-id={`tooltip-${entry.id}`}
        data-tooltip-content={`Job Code: ${entry.jobCode}`}
        key={`${isOutOfView ? 'highlighted-' : ''}${entry.id}`}
        data-id={entry.id}
        data-highlighted={entry.highlighted}
        ref={(el) => {
          if (el && !isOutOfView) {
            highlightedRefsMap.current.set(entry.id, el);
          } else {
            highlightedRefsMap.current.delete(entry.id);
          }
        }}
        className={classNames(styles.rowContainer, {
          [styles.selected]: entry.selected,
          [styles.selectable]: !!entry.onSelected,
          [styles.highlighted]: entry.highlighted,
          [styles.highlightedOutOfView]: isOutOfView,
        })}
        onClick={
          entry.onSelected
            ? (e) => {
                e.preventDefault();
                if (
                  entriesWithGridRow.filter(
                    (row) => row.selected && row.category === entry.category
                  ).length >= 8 &&
                  !entry.highlighted
                ) {
                  setTooltipEntryId(entry.id);
                  setTimeout(() => setTooltipEntryId(null), 5000);
                  return;
                }
                entry.onSelected(!entry.selected);
              }
            : isOutOfView
            ? () => scrollIntoView(entry.id)
            : undefined
        }
        style={entryStyle}
      >
        <Tooltip
          id={`tooltip-${entry.id}`}
          className={styles.tooltip}
          delayShow={500}
        />
        <span className={styles.content}>{entry.name}</span>
        <Icon className={styles.jobsIcon} />
        <span className={styles.timestamp}>
          <FormattedRelativeTimeConvenient date={entry.timestamp} />
        </span>
        {isOutOfView && (
          <Button
            className={styles.scrollButton}
            Icon={() =>
              isAbove ? (
                <FiChevronsDown size={20} />
              ) : (
                <FiChevronsUp size={20} />
              )
            }
            form={'bubble'}
            onClick={(e) => {
              e.stopPropagation();
              scrollIntoView(entry.id);
            }}
            title={'Scroll into view'}
          />
        )}
        <Tooltip
          id='max-entries-tooltip'
          content={`You have already ${MAX_HIGHLIGHTED_ENTRIES} selected entries in the ${entry.category} category`}
          isOpen={tooltipEntryId === entry.id}
          className={styles.tooltip}
        />
      </div>
    );
  };

  const filteredEntries = highlightedEntries
    .filter((entry) => entry.category === selectedPageCategory)
    .slice(0, MAX_HIGHLIGHTED_ENTRIES);

  const aboveEntries = filteredEntries.filter(
    (entry) => entry.position === 'above'
  );
  const belowEntries = filteredEntries.filter(
    (entry) => entry.position === 'below'
  );

  const modelTimelineElements = useMemo(
    () =>
      entriesWithGridRow.reduce((acc, entry) => {
        if (acc.length === 0) {
          return [
            {
              modelCode: entry.modelCode,
              rowStart: entry.gridRow,
              rowEnd: entry.gridRow + 1,
            },
          ];
        }

        const lastElement = acc[acc.length - 1];
        if (lastElement.modelCode === entry.modelCode) {
          return [
            ...acc.slice(0, -1),
            {
              ...lastElement,
              rowEnd: entry.gridRow + 1,
            },
          ];
        } else {
          return [
            ...acc,
            {
              modelCode: entry.modelCode,
              rowStart: entry.gridRow,
              rowEnd: entry.gridRow + 1,
            },
          ];
        }
      }, [] as { modelCode: string; rowStart: number; rowEnd: number }[]),
    [entriesWithGridRow]
  );
  return (
    <div className={styles.timeTravelContainer}>
      {aboveEntries.length > 0 && (
        <div className={styles.highlightedAboveContainer}>
          {aboveEntries.map((entry) => (
            <div
              key={`highlighted-above-${entry.id}`}
              className={classNames(
                styles.rowContainer,
                styles.highlightedOutOfView
              )}
              onClick={() => scrollIntoView(entry.id)}
            >
              <span className={styles.content}>{entry.name}</span>
              <entry.Icon className={styles.jobsIcon} />
              <span className={styles.timestamp}>
                <FormattedRelativeTimeConvenient date={entry.timestamp} />
              </span>
              <Button
                className={styles.scrollButton}
                Icon={() => <FiChevronsUp size={20} />}
                form={'bubble'}
                onClick={(e) => {
                  e.stopPropagation();
                  scrollIntoView(entry.id);
                }}
                title={'Scroll into view'}
              />
            </div>
          ))}
        </div>
      )}
      <div className={styles.grid} ref={containerRef}>
        {entriesWithGridRow.map((entry) => renderEntry(entry))}
        {modelTimelineElements.map((element) => {
          const gridRow = `${element.rowStart} / ${element.rowEnd}`;
          return (
            <React.Fragment key={`${element.modelCode}-${element.rowStart}`}>
              <div
                className={classNames(styles.modelBar, {
                  [styles.highlighted]: element.modelCode === activeModelCode,
                })}
                style={{ gridRow }}
                title={
                  element.modelCode === activeModelCode
                    ? `Active Model: ${element.modelCode}`
                    : `Model: ${element.modelCode}`
                }
              >
                {element.modelCode}
              </div>
              {_.range(element.rowStart, element.rowEnd - 1).map(
                (rowNumber) => {
                  const gridRow = `${rowNumber} / ${rowNumber + 2}`;
                  return (
                    <div
                      key={`jobConnection-${rowNumber}`}
                      className={classNames(styles.jobConnection, {
                        [styles.highlighted]:
                          element.modelCode === activeModelCode,
                      })}
                      style={{ gridRow }}
                    />
                  );
                }
              )}
            </React.Fragment>
          );
        })}
      </div>
      {belowEntries.length > 0 && (
        <div className={styles.highlightedBelowContainer}>
          {belowEntries.map((entry) => (
            <div
              key={`highlighted-below-${entry.id}`}
              className={classNames(
                styles.rowContainer,
                styles.highlightedOutOfView
              )}
              onClick={() => scrollIntoView(entry.id)}
            >
              <span className={styles.content}>{entry.name}</span>
              <entry.Icon className={styles.jobsIcon} />
              <span className={styles.timestamp}>
                <FormattedRelativeTimeConvenient date={entry.timestamp} />
              </span>
              <Button
                className={styles.scrollButton}
                Icon={() => <FiChevronsDown size={20} />}
                form={'bubble'}
                onClick={(e) => {
                  e.stopPropagation();
                  scrollIntoView(entry.id);
                }}
                title={'Scroll into view'}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AugurTimeline;
