import { TbArrowFork } from 'react-icons/tb';

import { Config, isAugurReportsType } from '../../../pages/augur/type';
import { isCFEType } from '../../../pages/augur/utils/cfe';
import { GalleryEntry } from '../../element-gallery/ElementGallery';
import {
  AugurReportElementMeta,
  reportElementMetas,
  ReportElementMetas,
} from '../report-elements/types/meta';
import { ReportElementTypes } from '../report-elements/types/type';
import {
  AugurSettingsElementMeta,
  settingsElementMetas,
  SettingsElementMetas,
} from '../settings-elements/types/meta';
import { SettingsElementTypes } from '../settings-elements/types/type';
import { ConfigPageElementForm } from '../../../pages/augur/utils/config.form';

/**
 * Returns a list of gallery entries with only the latest version of each element type.
 * Generally to be used with all specified types of reports or settings.
 */
export function elementsToGalleryEntries(
  elements: ReportElementMetas | SettingsElementMetas
): GalleryEntry[] {
  return Object.values(elements).map((versionsDict) => {
    const maxVersion = Object.keys(versionsDict).reduce((a, b) =>
      Number(a.replace('v', '')) > Number(b.replace('v', '')) ? a : b
    );
    const element: AugurReportElementMeta | AugurSettingsElementMeta =
      versionsDict[maxVersion];
    return {
      name: element.name,
      type: element.type,
      version: element.version,
      description: element.description,
      previewImage: element.imageIcon,
      Icon: isCFEType(element.type) ? TbArrowFork : undefined,
    };
  });
}

/**
 * Returns the elements meta for a given type and version.
 * Works for both report and settings elements.
 */
export function findElementMeta<
  T extends ReportElementTypes | SettingsElementTypes
>(
  type: T,
  version: ElementVersions<typeof type>
): T extends ReportElementTypes
  ? AugurReportElementMeta
  : AugurSettingsElementMeta {
  return isAugurReportsType(type)
    ? // @ts-ignore doesn't work because TS can't know that type and version are correlated
      reportElementMetas[type][version]
    : // @ts-ignore doesn't work because TS can't know that type and version are correlated
      settingsElementMetas[type][version];
}

export type ElementVersions<
  T extends SettingsElementTypes | ReportElementTypes =
    | ReportElementTypes
    | SettingsElementTypes
> = T extends SettingsElementTypes
  ? keyof SettingsElementMetas[T]
  : T extends ReportElementTypes
  ? keyof ReportElementMetas[T]
  : never;

export type SharedInputProps = {
  portalTarget?: HTMLElement;
};

export type ConfigFormComponentProps<TConfigType extends Record<string, unknown>> = {
  configValue: Config<TConfigType>;
  onConfigChange: (config: Config<TConfigType>) => void;
  elements: ConfigPageElementForm[];
}